import React, {useContext, useState} from 'react';
import "./PayForm.scss";
import accountBill from "../../img/accountBillImg.svg";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function PayForm() {
	const [isHovered, setIsHovered] = useState(false);
	const [sum, setSum] = useState("");
	const [sumError, setSumError] = useState(false);
	const {store} = useContext(Context)

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleInputClean = () => {
		setSum("");
		setSumError(false);
	}

	const handleInputChange = (e) => {
		e.preventDefault();
		const inputValue = e.target.value;
		const regex = /^$|^[0-9.,]+$/;

		if (regex.test(inputValue)) {
			setSum(inputValue);
			setSumError(false);
		}
	}

	const handlePayment = async () => {
		if (sum === "") {
			setSumError(true);
		} else {
			try {
				// await store.createPayment(sum, store.user.email, true);
				console.log("PayForm")

			} catch (error) {
				console.error('Ошибка при регистрации:', error);
			}
		}
	};


	return (
		<div className="pay_in_wrapper">
			<h2>Пополнить счет</h2>
			<div className="__pay_in">
				<div className="__form_wrapper">
					<div className="__form">
						<div className="__pay_input">
							<div className="__form_input">
								<div className="__input_icon_container">
									<input
										type="text"
										value={sum}
										onChange={handleInputChange}
										placeholder="Сумма в рублях"
										onMouseEnter={handleMouseEnter}
										onMouseLeave={handleMouseLeave}
										className={isHovered ? "__input hovered" : "__input"}
									/>
									<div
										className={isHovered ? "__icon_container hovered" : "__icon_container"}
										onMouseEnter={handleMouseEnter}
										onMouseLeave={handleMouseLeave}
										onClick={(e) => handleInputClean(e)}
									>
										<svg width="32" height="32" viewBox="0 0 32 32" fill="none"
										     xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd"
											      d="M8.28559 8.25878C8.63359 7.91252 9.1964 7.91392 9.54266 8.26192L15.9902 14.7418L22.4831 8.25982C22.8305 7.91298 23.3933 7.91345 23.7402 8.26087C24.087 8.6083 24.0865 9.17111 23.7391 9.51795L17.2442 16.0021L23.6939 22.4841C24.0402 22.8321 24.0387 23.395 23.6907 23.7412C23.3427 24.0875 22.7799 24.0861 22.4337 23.7381L15.986 17.2581L9.5169 23.7164C9.16948 24.0633 8.60667 24.0628 8.25982 23.7154C7.91298 23.368 7.91345 22.8051 8.26087 22.4583L14.7321 15.9979L8.28244 9.51585C7.93618 9.16785 7.93759 8.60504 8.28559 8.25878Z"
											      fill="#28293D"></path>
										</svg>
									</div>
								</div>
								{sumError && (
									<p className="error-message">Введите сумму пополнения</p>
								)}
							</div>
						</div>
						<button onClick={handlePayment}>Пополнить</button>
					</div>
					<div className="__image">
						<img src={accountBill} alt="img"/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(PayForm);