import React from 'react';
import "./ModalContact.scss";

function ModalContact({active, setActive, orderModalActive, setOrderModalActive, children}) {
	const inActiveModal = () => {
		if(active){
			setActive(false);
		}else{
			setOrderModalActive(false);
		}


	}
	return (
		<div className={active || orderModalActive ? "__modal_contact active" : "__modal_contact"} onClick={inActiveModal}>
			<div className={active || orderModalActive ? "__modal_content active" : "__modal_content"} onClick={e => e.stopPropagation()}>
				{children}
			</div>
		</div>
	);
}

export default ModalContact;