import React from 'react';
import "./ServiceCard.scss";
import more from "../../img/servicesMoreArrow.svg";
import {useNavigate} from "react-router-dom";

const ServiceCard = ({ service }) => {
	const navigate = useNavigate();

	return (
		<div className="service-card" onClick={() => {
			navigate(`/service-info/${service.url.toLowerCase()}`)
			window.scrollTo(0, 0);
		}}>
			<div className="__image">
				<img src={`https://ltd.dit-company.ru/static/${service.image}.png`} alt="img"/>
			</div>
			<h2>{service.name}</h2>

			<h5>{service.description}</h5>
			<button>
				Подробнее
				<img src={more} alt="img"/>
			</button>
		</div>
	);
};

export default ServiceCard;