import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from 'axios';
import {AuthResponse} from "../models/AuthResponse";
import {API_URL} from "../http";
import {Transaction} from "../models/Transaction";
import UserService from "../services/UserService";
import PaymentService from "../services/PaymentService";

export default class MyStore {
    user = {} as IUser;
    transaction = {} as Transaction;
    isAuth = false;
    isLoading = false;
    errors = {} as Record<string, string>;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setTransaction(transaction: Transaction){
        this.transaction = transaction;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setError(field: string, errorMessage: string) {
        this.errors[field] = errorMessage;
    }

    clearErrors() {
        this.errors = {};
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.clearErrors();
        } catch (e: any) {
            this.clearErrors();
            if (e.response?.data?.message) {
                this.clearErrors();
                if (e.response?.status === 400 && e.response.data?.message) {
                    if(e.response.data?.message === "Пользователь с таким email не найден"){
                        this.setError("email", e.response.data?.message);
                    }else if(e.response.data?.message === "Неверный пароль"){
                        this.setError("password", e.response.data?.message);
                    }
                }
            } else {
                console.log("Произошла ошибка при входе:", e.message);
                this.setError("general", "Произошла ошибка при входе");
            }
        }
    }

    async registration(email: string, password: string) {
        try {
            const response = await AuthService.registration(email, password);
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.clearErrors();
        } catch (e: any) {
            this.clearErrors();
            if (e.response?.status === 400 && e.response.data?.errors) {
                const validationErrors = e.response.data.errors;
                validationErrors.forEach((errorItem: any) => {
                    if (errorItem.path === 'email') {
                        this.setError(errorItem.path, "Напишите правильный email");
                    }
                    if (errorItem.path === 'password') {
                        this.setError(errorItem.path ,"Пароль должен содержать минимум 8 символов");
                    }
                })
            }else if(e.response.data?.message === "Пользователь с таким email не найден"){
                this.setError("email", e.response.data?.message);
            }
            else {
                console.log("Произошла ошибка при регистрации:", e.message);
                this.setError("general", "Произошла ошибка при регистрации");
            }
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({} as IUser);
        } catch (e: any) {
            console.log(e.response?.data?.message);
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
            console.log(response);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e: any) {
            console.log(e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }

    async confirmEmail(email: string){
        try {
            const response = await AuthService.confirmEmail(email);
            console.log(response)
            this.setAuth(true);
            this.clearErrors();
        } catch (e: any) {
            console.log("Произошла ошибка при входе:", e.message);
            this.setError("general", "Произошла ошибка при входе");
        }
    }

    async forgetPassword(email: string){
        try {
            const response = await AuthService.forgetPassword(email);
            console.log(response);
            this.clearErrors();
        } catch (e: any) {
            this.clearErrors();
            if (e.response?.data?.message) {
                if (e.response?.status === 400 && e.response.data?.message === "Ошибка при валидации") {
                    this.setError(e.response.data.errors[0].path ,"Напишите правильный email");
                }
                else if(e.response?.status === 400 && e.response.data?.message === "Пользователь с таким email не найден"){
                    this.setError("emailNotFound" ,"Пользователь с таким email не найден");
                }
            }
            else {
                console.log("Произошла ошибка при входе:", e.message);
                this.setError("general", "Произошла ошибка при отправке сообщения на почту");
            }
        }
    }

    async changeEmail(email: string, id: string){
        try {
            const response = await AuthService.changeEmail(email, id);
            console.log(response);
            this.setAuth(false);
            this.clearErrors();
        } catch (e: any) {
            this.clearErrors();
            console.log(e)
            if (e.response?.status === 400 && e.response.data?.errors) {
                this.setError(e.response.data.errors[0].path ,"Напишите правильный email");

            }else if(e.response?.status === 500){
                this.setError("email", "Пользователь с таким email уже зарегистрирован");
            }
            else {
                console.log("Произошла ошибка при входе:", e.message);
                this.setError("general", "Произошла ошибка при изменении почты");
            }
        }
    }

    async changePassword(password: string, activationLink?: string, id?: string){
        try {
            const response = await AuthService.changePassword(password, activationLink, id);
            console.log(response);
            this.setAuth(false);
            this.clearErrors();
        } catch (e: any) {
            this.clearErrors();
            if (e.response?.data?.message) {
                if (e.response?.status === 400 && e.response.data?.message) {
                    this.setError(e.response.data.errors[0].path ,"Пароль должен содержать минимум 8 символов");
                }
            } else {
                console.log("Произошла ошибка при входе:", e.message);
                this.setError("general", "Произошла ошибка при сбросе пароля");
            }
        }
    }


    async createOrder(data: FormData){
        try {
            const response = await UserService.createOrder(data);
            console.log(response);
        } catch (e: any) {
            console.log("Произошла ошибка при входе:", e.message);
            this.setError("general", "Произошла ошибка при отправке сообщения на почту");
        }
    }

    async getOrderedServices(id: string){
        try {
            const response = await UserService.getOrderedServices(id);
            console.log(response);
        } catch (e: any) {
            console.log("Произошла ошибка при входе:", e.message);
            this.setError("general", "Произошла ошибка при отправке сообщения на почту");
        }
    }










    async createPayment(sum: string, email: string, isPositive: boolean){
        try {
            const response = await PaymentService.pay(sum, email, isPositive);
            console.log(response);
        } catch (e: any) {
            console.log("Произошла ошибка при входе:", e.message);
            this.setError("general", "Произошла ошибка при оплате, store");
        }
    }
}