import React, {useState} from 'react';
import "./Solutions.scss";
import {motion} from "framer-motion";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import serviceTitle from "../../img/servicesImg.png"
import ConsultForm from "../../components/ConsultForm/ConsultForm";


function Solutions({services}) {
	const [modalActive, setModalActive] = useState(false);
	const [filter, setFilter] = useState('online');

	const filteredServices = services.filter(service =>
		filter === 'online' ? service.in_person === 1 : service.in_person === 0

	);
	return (
		<motion.div className="services"
		            intial={{width: 0}}
		            animate={{width: "100%"}}
		            exit={{x: window.innerWidth, transition: {duration: 0.3}}}>
			<div className="services-header">
				<div className="__info">
					<h1>Заказная разработка it-решений под ключ</h1>
					<p>Услуги полного цикла по созданию программного обеспечения:<br/> разработка, управление, тестирование и внедрение</p>
					<button onClick={() => setModalActive(true)}>Получить консультацию</button>
				</div>
				<div className="__image">
					<img src={serviceTitle} alt="img"/>
				</div>
			</div>
			<div className="container">
				<div className="__tumbler">
					<button className={filter === 'online' ? 'active' : ''}
					        onClick={() => setFilter('online')}>Онлайн
					</button>
					<button className={filter === 'in-person' ? 'active' : ''}
					        onClick={() => setFilter('in-person')}>Очно
					</button>
				</div>
				<div className="services-container">
					{filteredServices.map(service => (
						<ServiceCard key={service.id} service={service} />
					))}
				</div>
			</div>
			<ConsultForm modalActive={modalActive} setModalActive={setModalActive}/>
		</motion.div>
	)

}

export default Solutions;