import React, {useState} from 'react';
import "./ProfileMenu.scss";

function ProfileMenu({setSelectedButton}) {
	const [selectedItem, setSelectedItem ] = useState("account");

	const handleButtonChange = (data) => {
		setSelectedButton(data);
		setSelectedItem(data);
	}

	return (
		<div className="__menu">
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'account' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("account")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
							      d="M16 8a4 4 0 11-8 0 4 4 0 018 0zm-8 6a4 4 0 00-4 4v2h16v-2a4 4 0 00-4-4H8z"></path>
						</svg>
					</div>
					<span>Аккаунт</span>
				</button>
			</div>
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'products' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("products")}>
					<div className="__image">
						<svg className="__profile_svg" xmlns="http://www.w3.org/2000/svg"
						     viewBox="0 0 490 490"><g id="SVGRepo_bgCarrier"></g>
							<g id="SVGRepo_iconCarrier"><g>
								<path d="M449.739,259.991H40.26c-4.418,0-8,3.581-8,8V375.18c0,59,48,107,107,107h211.48c59,0,107-48,107-107V267.991 C457.739,263.572,454.157,259.991,449.739,259.991z M135,416.058c0,9.649-7.851,17.5-17.5,17.5s-17.5-7.851-17.5-17.5v-109 c0-9.649,7.851-17.5,17.5-17.5s17.5,7.851,17.5,17.5V416.058z M220,416.058c0,9.649-7.851,17.5-17.5,17.5s-17.5-7.851-17.5-17.5 v-109c0-9.649,7.851-17.5,17.5-17.5s17.5,7.851,17.5,17.5V416.058z M305,416.058c0,9.649-7.851,17.5-17.5,17.5 s-17.5-7.851-17.5-17.5v-109c0-9.649,7.851-17.5,17.5-17.5s17.5,7.851,17.5,17.5V416.058z M390,416.058 c0,9.649-7.851,17.5-17.5,17.5s-17.5-7.851-17.5-17.5v-109c0-9.649,7.851-17.5,17.5-17.5s17.5,7.851,17.5,17.5V416.058z"></path>
								<path d="M458.833,170.721h-41.81L338.856,23.918c-5.287-9.93-15.565-16.099-26.822-16.099c-4.943,0-9.872,1.233-14.256,3.567 c-14.78,7.87-20.401,26.298-12.531,41.077l62.966,118.257H141.786l62.968-118.257c7.869-14.781,2.247-33.208-12.533-41.078 c-4.383-2.333-9.311-3.566-14.255-3.566c-11.257,0-21.535,6.169-26.823,16.1L72.977,170.721h-41.81 C13.954,170.721,0,184.674,0,201.887s13.954,31.167,31.167,31.167h427.666c17.213,0,31.167-13.954,31.167-31.167 S476.046,170.721,458.833,170.721z M96.334,215.76c-7.663,0-13.873-6.211-13.873-13.873c0-7.661,6.21-13.873,13.873-13.873 s13.873,6.212,13.873,13.873C110.207,209.549,103.997,215.76,96.334,215.76z M393.666,215.76c-7.663,0-13.873-6.211-13.873-13.873 c0-7.661,6.21-13.873,13.873-13.873c7.663,0,13.873,6.212,13.873,13.873C407.539,209.549,401.329,215.76,393.666,215.76z"></path> </g> </g>
						</svg>
					</div>
					<span>Продукты</span>
				</button>
			</div>
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'service' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("service")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
							      d="M4.5 3v18h4.91A7.5 7.5 0 0118.5 9.365V7l-4-4h-10zm16 13a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-4.79-2.875h-2v4l3.031 1.75 1-1.732-2.031-1.173v-2.845z"></path>
						</svg>
					</div>
					<span>Услуги</span>
				</button>
			</div>
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'settings' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("settings")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
							      d="M13.8 3h-3.6v2.027c-.66.17-1.285.431-1.858.77L6.91 4.363 4.363 6.91l1.434 1.433a7.157 7.157 0 00-.77 1.858H3v3.6h2.027c.17.66.431 1.285.77 1.858L4.363 17.09l2.546 2.546 1.433-1.434c.573.339 1.197.6 1.858.77V21h3.6v-2.027a7.157 7.157 0 001.858-.77l1.433 1.434 2.546-2.546-1.434-1.434a7.16 7.16 0 00.77-1.857H21v-3.6h-2.027a7.158 7.158 0 00-.77-1.858l1.434-1.433-2.546-2.546-1.434 1.434a7.156 7.156 0 00-1.857-.77V3zm-4.5 9a2.7 2.7 0 115.4 0 2.7 2.7 0 01-5.4 0z"></path>
						</svg>
					</div>
					<span>Настройки</span>
				</button>
			</div>

			<div className="__item">
				<button className={`__list_item ${selectedItem === 'support' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("support")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
							      d="M21.002 17v-5a9.113 9.113 0 00-.055-1 9.001 9.001 0 00-17.945 1v5h5v-6H5.578a6.502 6.502 0 0112.848 0h-2.424v6h.899a6.988 6.988 0 01-3.289 1.814 2 2 0 10.217 2A9.007 9.007 0 0019.486 17h1.516z"></path>
						</svg>
					</div>
					<span>Поддержка</span>
				</button>
			</div>
		</div>
	);
}

export default ProfileMenu;