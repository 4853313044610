import React, {useState} from 'react';
import "./Profile.scss";
import {observer} from "mobx-react-lite";
import ProfileSettings from "../../components/ProfileSettings/ProfileSettings";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import ProfileProducts from "../../components/ProfileProducts/ProfileProducts";
import ProfileServices from "../../components/ProfileServices/ProfileServices";
import ProfileAccount from "../../components/ProfileAccount/ProfileAccount";
import ProfileSupport from "../../components/ProfileSupport/ProfileSupport";



function Profile() {
	const [selectedButton, setSelectedButton] = useState('account');

	const renderComponent = () => {
		switch (selectedButton) {
			case 'account':
				return <ProfileAccount/>;
			case 'products':
				return <ProfileProducts/>;
			case 'service':
				return <ProfileServices/>;
			case 'settings':
				return <ProfileSettings />;
			case 'support':
				return <ProfileSupport />;
			default:
				return null;
		}
	};


	return (
		<div className="__profile">
			<div className="container-top">
				<div className="__wrapper">
					<div className="__menu_container">
						<ProfileMenu setSelectedButton={setSelectedButton}/>
					</div>
					<div className="__content_container">{renderComponent()}</div>
				</div>
			</div>
		</div>
	);
}

export default observer(Profile);
