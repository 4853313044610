import React from 'react';
import "./Rules.scss";

function Rules() {
	return (
		<div className="rules">
			<div className="container-top">
				<div className="container">
					<h1>Правила пользования</h1>
					<p>Перед тем как воспользоваться услугами сервиса ООО "ДИТ" (далее "Сервис"), Пользователь обязан
						ознакомиться в полном объеме с Правилами и условиями предоставления услуг. Использование услуг
						Сервиса возможно только в случае, если Пользователь принимает все условия соглашения.</p>
					<h2>Термины и определения</h2>
					<ul className="__dot_list">
						<li>ООО "ДИТ" ("Сервис") – это сервис по предоставлению IT-услуг, включая разработку сайтов,
							приложений и другие IT-услуги, расположенный в сети Интернет по адресу dit-company.ru.
						</li>
						<li>Пользователь - это любое физическое или юридическое лицо, согласившееся со всеми условиями,
							предложенными Сервисом, и присоединившееся к данному соглашению.
						</li>
						<li>Баланс - денежные средства, внесенные Пользователем на его аккаунт в личном кабинете на
							сайте Сервиса, которые могут быть использованы для оплаты всех услуг и сервисов,
							предоставляемых ООО "ДИТ", даже тех, которые не размещены на данном сайте.
						</li>
						<li>Платеж - перевод денежных средств от Пользователя к Сервису.</li>
						<li>Заявка - информация, поданная Пользователем с использованием средств Сервиса в электронном
							виде, свидетельствующая о его намерениях воспользоваться услугами Сервиса на условиях,
							предложенных Сервисом и указанных в параметрах заявки.
						</li>
						<li>Стороны - совместно Пользователь и Сервис.</li>
					</ul>
					<h2>Предмет соглашения и порядок вступления его в силу</h2>
					<ul className="__sublist">
						<li>Данное соглашение регламентирует отношения между Пользователем и Сервисом по поводу услуг,
							предоставляемых Сервисом Пользователю, и отменяет все предшествующие договоренности между
							Сервисом и Пользователем по данному предмету.
						</li>
						<li>Данное соглашение считается принятым на условиях публичной оферты, акцептуемой Пользователем
							в ходе подачи им Заявки, являющейся неотъемлемой частью настоящего соглашения.
						</li>
						<li>Публичной офертой признается отображаемая Сервисом информация о параметрах и условиях
							Заявки.
						</li>
						<li>Акцептом публичной оферты признается совершение Пользователем действий по завершению
							формирования Заявки, подтверждающих его намерение совершить сделку с Сервисом на условиях,
							предложенных Сервисом непосредственно перед завершением формирования Заявки.
						</li>
						<li>В случае регистрации на Интернет-сайте Сервиса настоящее соглашение вступает в действие в
							момент проставления галочки перед словами «Я согласен с условиями оферты» и нажатия кнопки
							«Зарегистрироваться».
						</li>
						<li>Данное соглашение вступает в действие с момента регистрации Пользователем на сайте.</li>
						<li>Данное соглашение прекращает свое действие с момента поступления на реквизиты,
							предоставленные Пользователем, денежных средств в сумме, предусмотренной параметрами Заявки
							Пользователя, либо с момента аннулирования заявки.
						</li>
						<li>Данное соглашение прекращает свое действие с момента поступления на реквизиты,
							предоставленные Пользователем, денежных средств в сумме, предусмотренной параметрами Заявки
							Пользователя, либо с момента аннулирования заявки.
						</li>
						<li>Стороны признают настоящее соглашение в электронной форме равнозначным по юридической силе
							договору, заключенному в письменной форме.
						</li>
						<li>Сервис оставляет за собой право в одностороннем порядке вносить изменения в данное
							соглашение без соответствующего уведомления об этом Пользователя, но с обязательной
							публикацией актуальной версии соглашения на данной странице.
						</li>
					</ul>
					<h2>Стоимость оказываемых услуг</h2>
					<ul className="__sublist">
						<li>Размер вознаграждения Сервиса за указанные действия отражается в Заявке и подтверждается
							Пользователем на одной из страниц пользовательского интерфейса.
						</li>
						<li>Стоимость услуг Сервиса устанавливается руководством Сервиса и публикуется на
							Интернет-сайте Сервиса.
						</li>
						<li>Сервис вправе самостоятельно изменять стоимость услуг и взимаемых комиссий в любое время в
							одностороннем порядке, о чем уведомляет Пользователей Сервиса предварительным размещением
							информации об этих изменениях на Интернет-сайте Сервиса.
						</li>
					</ul>
					<h2>Оплата</h2>
					<ul className="__sublist">
						<li>Услуги, доступные в Сервисе, предоставляются на условиях предоплаты.</li>
						<li>Для осуществления Оплаты Услуг в Сервисе Пользователю необходимо пополнить баланс
							аккаунта.
						</li>
						<li>Оплата Услуг списания денежных средств с баланса аккаунта процессе оформления услуги.
						</li>
						<li>После заказа услуги товара клиентом, Сервис обрабатывает заявку Пользователя,
							подтверждает возможность выполнения заявки и его рассчитывает его стоимость. При согласии со
							стоимостью выполнения услуги пользователь при помощи программного обеспечения Сервиса
							оплачивает услугу и денежные средства списываются с баланса аккаунта.
						</li>
						<li>Пользователь вправе отказаться от исполнения услуги при условии оплаты Сервису фактически
							понесенных Сервисом расходов.
						</li>
						<li>Возврат остатка неиспользованных средств производится по письменной заявке Клиента. Заявка для возврата остатка неиспользованных средств доставляется в офис ООО «ДИТ» (почтой, курьером или лично). При этом, пользователю необходимо указать в Заявке для возврата остатка неиспользованных средств ID пользователя, электронную почту, к которой привязан аккаунт, паспортные данные, банковские реквизиты. Возврат неиспользованных средств производится в установленные законом сроки.</li>
					</ul>
					<h2>Условия предоставления услуг</h2>
					<ul className="__sublist">
						<li>Заказ услуг Сервисом осуществляется Пользователем путем направления Заявки через
							Интернет-сайт Сервиса.
						</li>
						<li>Управление процессом заказа услуг или получение информации о ходе выполнения услуги
							Пользователем производится при помощи соответствующего пользовательского интерфейса,
							расположенного на Интернет-сайте Сервиса.
						</li>
						<li>Обработка Заявок Пользователя осуществляется Сервисом в строгом соответствии с политикой
							конфиденциальности, а также политикой по противодействию отмыванию денег и предотвращению
							операций, носящих незаконный характер.
						</li>
						<li>Любая завершенная операция, проведенная Сервисом по Заявке Пользователя, считается
							неотменяемой, т.е. не может быть отменена Пользователем после ее завершения.
						</li>
						<li>Сервис имеет право привлекать для исполнения своих обязательств сторонних исполнителей.
						</li>
						<li>Сервис имеет право отказать Пользователю в дальнейшем обслуживании в случае нарушения Пользователем любого из пунктов настоящего соглашения.</li>
					</ul>
					<h2>Ограничение ответственности</h2>
					<ul className="__sublist">
						<li>Сервис предоставляет свои услуги на условиях «как есть», как они описаны на страницах сайта Сервиса и не предлагает никаких дополнительных гарантий.
						</li>
						<li>Сервис не несет ответственности за убытки, неполученную прибыль и иные издержки Пользователя, возникшие в результате невозможности получения доступа к сайту и услугам Сервиса.
						</li>
						<li>Сервис не несет ответственности за убытки, неполученную прибыль и иные издержки Пользователя, явившиеся результатом задержек, ошибок или сбоев в проведении платежей.
						</li>
					</ul>
					<h2>Передача выполненной услуги Пользователю</h2>
					<ul className="__sublist">
						<li>Передача выполненной услуги Пользователю происходит в рабочем порядке в соответствии с законодательством Российской Федерации. </li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Rules;