import React from 'react';
import "./About.scss";
import aboutImg from "../../img/aboutImg.png";

function About(props) {
	return (
		<div className="about">
			<div className="container">
				<div className="about-content">
					<div className="about-image">
						<img src={aboutImg} alt="img" />
					</div>
					<div className="about-text">
						<h2>Кто мы?</h2>
						<p>
							Добро пожаловать в ДИТ – вашу надежную опору в мире информационных технологий! Мы – команда профессионалов, специализирующихся на веб-разработке и аутсорсинге сетей и серверов. С момента основания нашей компании, мы стремимся предоставлять нашим клиентам высококачественные IT-решения, которые упрощают и оптимизируют их бизнес-процессы.
							Мы аккредитованы в реестре IT-компаний, что подтверждает наш высокий уровень профессионализма и соответствие строгим стандартам отрасли. Это дает нашим клиентам дополнительную уверенность в качестве предоставляемых услуг.
							Наша миссия – сделать IT простым и доступным для всех. Мы берем на себя все заботы, связанные с технологиями, чтобы вы могли сосредоточиться на главном – развитии вашего бизнеса. Сотрудничая с нами, вы получаете не просто IT-услуги, а полноценного партнера, который всегда рядом и готов поддержать вас на каждом этапе развития вашего бизнеса.
							ДИТ – делаем IT проще!</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;