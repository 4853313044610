import React from 'react';
import "./PartnersBanner.scss";
import {useNavigate} from "react-router-dom";

function PartnersBanner(props) {
	const navigate = useNavigate();

	const linkToPartners = () => {
		navigate("/partners");
		window.scrollTo(0,0);
		props.setSelectedLink(3);
	}
	return (
		<div className="partners-banner">
			<div className="container">
				<div className="banner-wrapper">
					<h1>Станьте нашим партнером</h1>
					<p>Объедините усилия с ДИТ для совместного роста и успеха. Узнайте больше о наших партнерских программах и возможностях для вашего бизнеса.</p>
					<button onClick={linkToPartners}>
						Стать партнером
					</button>
				</div>
			</div>
		</div>
	);
}

export default PartnersBanner;