import React, {useContext, useState} from 'react';
import "./ChangeEmailForm.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

function ChangeEmailForm() {
	const {store} = useContext(Context);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");

	const emailHandler = (e) =>{
		e.preventDefault();
		setEmail(e.target.value)
	}
	const handleChangeEmail = async () => {
		try {
			await store.changeEmail(email, store.user.id);
			if (!Object.keys(store.errors).length) {
				navigate('/signin');
			}
		} catch (error) {
			console.error('Ошибка при входе:', error);
		}
	}

	return (
		<div className="change_email_wrapper">
			<h3>Изменение почты</h3>
			<p>После изменения почты произойдет выход из аккаунта на всех устройствах, сайтах и приложениях, где вошли с текущей почтой</p>

			<input
				type={'text'}
				value={email}
				onChange={(e) => emailHandler(e)}
				placeholder="Новый пароль"
				className={store.errors.email ? 'invalid-input' : ''}
				autoComplete="current-password"
			/>

			{store.errors.email && <div className="error-message">{store.errors.email}</div>}
			{store.errors.general && <div className="error-message">{store.errors.general}</div>}

			<button onClick={handleChangeEmail}>Изменить</button>
		</div>
	);
}

export default observer(ChangeEmailForm);
