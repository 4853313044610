import React from 'react';
import "./ProfileProducts.scss";
import emptyImg from "../../img/transactionEmptyImg.svg";

function ProfileProducts() {

	const service = [
		{
			name: "TATI",
			duration: "От одной недели",
			logo: "websiteMain",
			price: 500,
			status: "created"

		}
	]

	return (
		<div className="products">
			{service ?
				<div className="__products_wrapper">
					{
						service.map((item, i) =>
							<div className="product-item" key={i}>
								<h2>{item.name}</h2>
								<p>{item.duration}</p>
								<div className="__steps">
									<div className="__process">
										<div className="__column">
											<div className="line start"></div>
											<div
												className={item.status === "created" || item.status === "pay" || item.status === "confirmed" ?
													"circle done" :
													"circle"}></div>
											<div className="title">
												<h5>Обработка</h5>
											</div>
										</div>
										<div className="__column">
											<div className={item.status === "pay" || item.status === "confirmed" ?
												"circle done" :
												"circle"}></div>
											<div className="title">
												<h5>Оплата</h5>
											</div>
										</div>
										<div className="__column">
											<div className={item.status === "confirmed" || item.status === "finished" ?
												"circle done" :
												"circle"}></div>
											<div className="title">
												<h5>В работе</h5>
											</div>
										</div>
										<div className="__column">
											<div className="line end"></div>
											<div className={item.status === "finished" ?
												"circle done" :
												"circle"}></div>
											<div className="title">
												<h5>Завершено</h5>
											</div>
										</div>
									</div>
								</div>
								<div className="__purchase">
									{
										item.status === "pay" &&
										<div className="total-price">
											<p>К оплате: <span>{item.price}&#8381;</span></p>
										</div>
									}
									<button disabled={item.status === "created"}>
										{item.status === "created" ? "Обработка заказа" : null}
										{item.status === "pay" ? "Оплатить" : null}
										{item.status === "confirmed" ? "Оплачено" : null}
										{item.status === "finished" ? "" : null}
									</button>
								</div>
							</div>
						)
					}
				</div> :
				<div className="__empty">
					<div className="__image">
						<img src={emptyImg} alt="img"/>
					</div>
					<h2>На данный момент у вас нет заказанных продуктов и услуг</h2>
				</div>
			}


		</div>

	);
}

export default ProfileProducts;