import React, {useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import "./Navigation.scss";
import logo from "../../img/logoBlack.svg";
import profileIcon from "../../img/profile.svg";
import {Context} from "../../index";


function Navigation(props) {
	const {store} = useContext(Context);
	const [menuActive, setMenuActive] = useState(false);
	const navigate = useNavigate();

	const items = [{value: "Услуги", href: "/services", key: 1},
		{value: "Продукты", href: "/products", key: 2},
		{value: "Партнерам", href: "/partners", key: 3},
		{value: "Контакты", href: "/contact", key: 4},]

	function refreshPage(){
		navigate("/");
		window.location.reload();
		window.scrollTo(0,0);
	}
	function handleItemClick(key){
		props.setSelectedLink(key);
		setMenuActive(false);
		document.body.classList.remove("lock");
		window.scrollTo(0,0);
	}

	const goToSignIn = () => {
		navigate("/signin");
		setMenuActive(false);
		document.body.classList.remove("lock");
		window.scrollTo(0,0);
		props.setSelectedLink(null);
	}
	const goToSignUp = () => {
		navigate("/signup");
		setMenuActive(false);
		document.body.classList.remove("lock");
		window.scrollTo(0,0);
		props.setSelectedLink(null);
	}

	const goToProfile = () => {
		navigate("/profile");
		setMenuActive(false);
		document.body.classList.remove("lock");
		window.scrollTo(0,0);
		props.setSelectedLink(null);
	}


	return (
		<div className="nav">
			<div className="container">
				<div className="nav-body">
					<Link to="/" className="nav-logo"
					      onClick={refreshPage}>
						<img src={logo} alt="img"/>
					</Link>
					<nav className={menuActive ? "nav-menu active" : "nav-menu"}>
						<div className="nav-before"></div>
						<ul className="nav-list">
							{items.map(item =>
								<li key={item.key} onClick={() => handleItemClick(item.key)}>
									<Link to={item.href}
									      className={item.key === props.selectedLink ? 'nav-link selected' : 'nav-link'}>
										{item.value}
									</Link>
								</li>
							)}
						</ul>
						{store.user.isActivated ? (
							<div className="burger-profile">

								<button className="burger-profile" onClick={goToProfile}>
									<img src={profileIcon} alt="img"/>
								</button>
								<div className="balance">
									<p>Баланс: 143&#8381;</p>
								</div>
							</div>

						) : (
							<div className="nav-buttons burger-buttons">
								<button onClick={goToSignUp} className="sign-in">Регистрация</button>
								<button onClick={goToSignIn} className="log-in">Войти</button>
							</div>
						)}
					</nav>
					{store.user.isActivated ? (
						<div className="profile">

							<button onClick={goToProfile}>
								<img src={profileIcon} alt="img"/>
							</button>
							<div className="balance">
								<p>Баланс: { Math.floor(store.user.balance)}&#8381;</p>
							</div>
						</div>
					) : (
						<div className="nav-buttons">
							<button onClick={goToSignUp} className="sign-in">Регистрация</button>
							<button onClick={goToSignIn} className="log-in">Войти</button>
						</div>
					)}

					<div className={menuActive ? "nav-burger active" : "nav-burger"}
						onClick={() => {
							setMenuActive(!menuActive);
							if (menuActive) {
								document.body.classList.remove("lock");
							} else {
								document.body.classList.add("lock");
							}
						}}
					>
						<span></span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(Navigation);