import axios from "axios";
import {API_URL} from "../http";

export default class DataService {

	static async getServices() {
		try {
			const response = await axios.get(`${API_URL}/services`);
			return response.data;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	static async getProducts() {
		try {
			const response = await axios.get(`${API_URL}/products`);
			return response.data;
		} catch (error) {
			console.error(error);
			return null;
		}
	}
}