import React, {useState} from 'react';
import "./Contacts.scss";
import {motion} from "framer-motion";
import MapPoint from "../../components/MapPoint/MapPoint";
import telegram from "../../img/contactsTelegram.svg";
import vk from "../../img/contactsVk.svg";
import linkedIn from "../../img/contactsLinkedIn.svg";
import axios from "axios";

function Contacts() {
	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [email, setEmail] = useState("");
	const [info, setInfo] = useState("");
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);


	const [isValidName, setIsValidName] = useState(true);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isValidNumber, setIsValidNumber] = useState(true);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);

	const handleNameChange = (event) => {
		setName(event.target.value);
		setIsValidName(true)
	};
	const handleNumberChange = (event) => {
		let inputValue = event.target.value;
		inputValue = inputValue.replace(/[^0-9+()\-\s]/g, '');
		if (!inputValue.startsWith("+")) {
			inputValue = "+" + inputValue;
		}
		setNumber(inputValue);
		setIsValidNumber(true);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setIsValidEmail(true);
	};
	const handleInfoChange = (event) => {
		setInfo(event.target.value);
	}
	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const handleSubmit = () => {
		const isValidName = name !== "";
		setIsValidName(isValidName);

		const isPhoneNumValid = number !== "" && number.length >= 8;
		setIsValidNumber(isPhoneNumValid);

		setIsPrivacyPolicyValid(isPrivacyPolicyAgreed);

		if(isValidName && isValidEmail && isPrivacyPolicyValid ){
			const data = {
				service_id: "service_boof0mc",
				template_id: "template_rcqi3jh",
				user_id: "3QdAXNkHhOeDGWs4u",
				template_params: {
					'name': name,
					'number': number,
					'email': email,
					'info': info
				}
			};

			axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
				.then(response => {
					console.log("Successful sent");
				})
				.catch(error => {
					console.error('Oops... ' + error);
				});
			setName("");
			setNumber("");
			setEmail("");
			setInfo("");
			setIsPrivacyPolicyAgreed(false);
		}
	}

	return (
		<motion.div
			className="contacts"
			intial={{width: "0"}}
			animate={{width: "100%"}}
			exit={{x: window.innerWidth, transition: {duration: 0.3}}}
		>
			<div className="contact-us">
				<h1>Свяжитесь с нами</h1>
				<div className="contact-info">
					<p>Наш телефон</p>
					<ul>
						<li><a href="tel:+79673671612">+7(967)367-16-12</a></li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Наша почта</p>
					<ul>
						<li><a href="mailto:info@dit-company.ru">info@dit-company.ru</a></li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Реквизиты</p>
					<ul>
						<li>ООО "ДИТ"</li>
						<li>ИНН 1660098058</li>
						<li>ОГРН 1071690007311</li>
						<li>Адрес: 420029, РТ, г. Казань, ул. Сибирский тракт, д. 32</li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Соцсети</p>
					<div className="social-wrapper">
						<a href="#" target="_blank" rel="noopener"><img src={telegram} alt="#"/></a>
						<a href="#" target="_blank" rel="noopener"><img src={linkedIn} alt="#"/></a>
						<a href="#" target="_blank" rel="noopener"><img src={vk} alt="#"/></a>
					</div>
				</div>
				{/*<MapPoint/>*/}
			</div>
			<div className="contact-form">
				<div className="form">
					<div className="label-group">
						<label>Имя<span>*</span></label>
						<input type="text"
						       value={name}
						       onChange={handleNameChange}
						       className={!isValidName ? "invalid" : ""}
						/>
						{!isValidName && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>Номер телефона<span>*</span></label>
						<input type="text"
						       value={number}
						       onChange={handleNumberChange}
						       className={!isValidNumber ? "invalid" : ""}
						/>
						{!isValidNumber && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>E-mail</label>
						<input type="text"
						       value={email}
						       onChange={handleEmailChange}
						       className={!isValidEmail ? "invalid" : ""}
						/>
						{!isValidEmail && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>Сообщение</label>
						<textarea value={info}
						          onChange={handleInfoChange}
						/>
					</div>
					<button onClick={handleSubmit}>Отправить</button>
					<div className="form-agree">
						<div className="agree-text">
							<input
								type="checkbox"
								id="privacyPolicyAgree"
								checked={isPrivacyPolicyAgreed}
								onChange={handlePrivacyPolicyChange}
							/>
							<label htmlFor="privacyPolicyAgree">
								Вы соглашаетесь с
								<a href="/privacy"> политикой конфиденцальности</a>
								<span>*</span>
							</label>
						</div>
						{!isPrivacyPolicyValid && (
							<p className="error-message">Примитие политику конфиденцальности</p>
						)}
					</div>
				</div>
			</div>
		</motion.div>

	);
}

export default Contacts;