import React from 'react';
import "./ProductInfo.scss";
import {useParams} from "react-router-dom";
import {API_URL} from "../../http";
import Spinner from "../Spinner/Spinner";

function ProductInfo({products}) {
	const { url } = useParams();
	const product = products.find(item => item.url === url);

	if (!product) {
		return <Spinner/>;
	}

	return (
		<div className="product-info">
			<div className="container">
				<div className="container-top">
					<div className="product-title">
						<div className="product-logo">
							<img src={`${API_URL}/static/${product.logo}`} alt="img"/>
							<div className="logo-name">
								<h1>{product.name}</h1>
								<p>{product.direction}</p>
							</div>
						</div>
						<div className="purchase-info">
							<p><span>{product.price}&#8381;</span></p>
						</div>
					</div>
					<div className="info">
						<h2>Описание продукта</h2>
						<p>{product.infoFull}</p>
					</div>
					<div className="total-price">
						{/*<p>Итого: <span>${total}</span></p>*/}
					</div>
					{product && <button className="purchase-btn soon">Скоро</button>}
					{/*<div className="info-video">*/}
					{/*	<h3>Как это работает?</h3>*/}
					{/*	<img src={`${API_URL}/static/${product.infoVideo}`} alt="img"/>*/}
					{/*</div>*/}
				</div>
			</div>
		</div>
	);
}

export default ProductInfo;