import React, {useContext, useEffect, useState} from 'react';
import "./History.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import emptyImg from "../../img/transactionEmptyImg.svg";
import UserService from "../../services/UserService";
import Spinner from "../../components/Spinner/Spinner";

function History() {
	const {store} = useContext(Context);
	const [transaction, setTransaction] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		async function fetchData() {
			try {
				const response = await UserService.fetchTransactions(store.user.id);
				setTransaction(response.data);
				setLoading(false);

			} catch (e) {
				console.log(e);
				setLoading(false);
			}
		}

		fetchData().then(r => r);
	}, []);

	const formatDate = (utcDateString) => {
		const date = new Date(utcDateString);
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		};
		return date.toLocaleString(undefined, options);
	};


	return (
		<div className="transactions">
			{
				loading ?
					<Spinner/>
					:
					transaction.length > 0 ?
						<div className="wrapper">
							<div className="table-wrapper">
								<table className="transaction-table">
									<thead>
									<tr>
										<th>ID транзакции</th>
										<th>Тип услуги</th>
										<th>Сумма</th>
										<th>Дата</th>
									</tr>
									</thead>
									<tbody>
									{transaction.map((transaction, i) => (
										<tr key={i}>
											<td>{transaction.transactionId}</td>
											<td>{transaction.type}</td>
											<td>{transaction.amount}</td>
											<td>{formatDate(transaction.time)}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
						:
						<div className="__empty">
							<div className="__image">
								<img src={emptyImg} alt="img"/>
							</div>
							<h3>На данный момент у вас нет транзакций</h3>
						</div>
			}

		</div>
	);
}

export default observer(History);