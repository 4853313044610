import React from 'react';
import "./OrderInfo.scss"
import OrderInfoCard from "../OrderInfoCard/OrderInfoCard";
import orderImg1 from "../../img/benfitListImg1.svg";
import orderImg2 from "../../img/benfitListImg2.svg";
import orderImg3 from "../../img/benfitListImg3.svg";
import orderImg4 from "../../img/benfitListImg4.svg";

function OrderInfo() {
	return (
		<div className="orderInfo">
			<h2>Почему выбирают нас?</h2>
			<div className="orderInfo__cards">
				<OrderInfoCard
					imageUrl={orderImg1}
					title="Надежность"
					text="Мы гарантируем стабильную работу ваших IT-систем."
				/>
				<OrderInfoCard
					imageUrl={orderImg2}
					title="Индивидуальный подход"
					text="Мы понимаем, что каждый бизнес уникален, поэтому предлагаем решения, которые идеально подходят именно вам."
				/>
				<OrderInfoCard
					imageUrl={orderImg3}
					title="Квалифицированная команда"
					text="Наши специалисты обладают глубокими знаниями и опытом, чтобы решать самые сложные задачи."
				/>
				<OrderInfoCard
					imageUrl={orderImg4}
					title="Инновации"
					text="Мы постоянно внедряем новейшие технологии, чтобы предложить вам лучшие решения."
				/>
			</div>
		</div>
	);
}

export default OrderInfo;