import React, {useContext, useEffect, useState} from 'react';
import ModalContact from "../ModalContact/ModalContact";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function OrderForm({ orderModalActive, setOrderModalActive, serviceName, serviceId }) {
	const {store} = useContext(Context);

	useEffect(() => {
		if (orderModalActive) {
			document.body.classList.add('lock');
		} else {
			document.body.classList.remove('lock');
		}
	}, [orderModalActive]);

	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [details, setDetails] = useState("");
	const [file, setFile] = useState(null);
	const [filePreview, setFilePreview] = useState(null);
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);

	const [isValidName, setIsValidName] = useState(true);
	const [isValidNumber, setIsValidNumber] = useState(true);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);

	const handleNameChange = (event) => {
		setName(event.target.value);
		setIsValidName(true);
	};

	const handleNumberChange = (event) => {
		let inputValue = event.target.value;
		inputValue = inputValue.replace(/[^0-9+()\-\s]/g, '');
		if (!inputValue.startsWith("+")) {
			inputValue = "+" + inputValue;
		}
		setNumber(inputValue);
		setIsValidNumber(true);
	};

	const handleDetailsChange = (event) => {
		setDetails(event.target.value);
	};

	const handleFileChange = (event) => {
		setFile(event.target.files[0]);
		setFilePreview(URL.createObjectURL(event.target.files[0]));
	};

	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const handleSubmit = async () => {
		const isValidName = name !== "";
		setIsValidName(isValidName);

		const isPhoneNumValid = number !== "" && number.length >= 8;
		setIsValidNumber(isPhoneNumValid);

		setIsPrivacyPolicyValid(isPrivacyPolicyAgreed);

		if (isValidName && isPhoneNumValid && isPrivacyPolicyValid) {
			const data = {
				id: store.user.id,
				email: store.user.email,
				serviceName,
				serviceId,
				name,
				number,
				details
			};

			const formData = new FormData();
			// for (const key in data) {
			// 	if (data.hasOwnProperty(key)) {
			// 		formData.append(key, data[key]);
			// 	}
			// }

			if (file) {
				formData.append('file', file);
			}


			try {
				await store.createOrder(formData);
				console.log("Order created successfully");
			} catch (error) {
				console.error('Ошибка при отправке заказа:', error);
			}
			setOrderModalActive(false);
			setName("");
			setNumber("");
			setDetails("");
			setFile(null);
			setFilePreview(null);
			setIsPrivacyPolicyAgreed(false);
		}
	};

	return (
		<ModalContact orderModalActive={orderModalActive} setOrderModalActive={setOrderModalActive}>
			<div className="form">
				<h2>Заказ услуги <span>"{serviceName}"</span></h2>
				<div className="input-group">
					<div className="form-input">
						<label>Имя<span>*</span></label>
						<input type="text"
						       value={name}
						       onChange={handleNameChange}
						       className={!isValidName ? "invalid" : ""}
						/>
						{!isValidName && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="form-input">
						<label>Номер телефона<span>*</span></label>
						<input type="text"
						       value={number}
						       onChange={handleNumberChange}
						       className={!isValidNumber ? "invalid" : ""}
						/>
						{!isValidNumber && (
							<p className="error-message">Введите свой номер телефона</p>
						)}
					</div>
					<div className="form-input">
						<label>Детали</label>
						<textarea
							value={details}
							onChange={handleDetailsChange}
						/>
					</div>
					<div className="form-input-file">
						<label htmlFor="fileUpload" className="custom-file-upload">
							Загрузить файл
						</label>
						<input type="file"
						       id="fileUpload"
						       onChange={handleFileChange}
						/>
						{filePreview && (
							<div className="file-preview">
								{file.type.startsWith('image/') ? (
									<img src={filePreview} alt="Preview" />
								) : (
									<a href={filePreview} target="_blank" rel="noopener noreferrer">Посмотреть</a>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="btn">
					<button onClick={handleSubmit}>Отправить</button>
					<div className="form-agree">
						<div className="agree-text">
							<input
								type="checkbox"
								id="privacyPolicyAgree"
								checked={isPrivacyPolicyAgreed}
								onChange={handlePrivacyPolicyChange}
							/>
							<label htmlFor="privacyPolicyAgree">
								Вы соглашаетесь с
								<a href="/privacy"> политикой конфиденцальности</a>
								<span>*</span>
							</label>
						</div>
						{!isPrivacyPolicyValid && (
							<p className="error-message">Примитие политику конфиденцальности</p>
						)}
					</div>
				</div>
			</div>
		</ModalContact>
	);
}

export default observer(OrderForm);