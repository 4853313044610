import React, {useContext} from 'react';
import "./ConfirmLogout.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function ConfirmLogout({active, setActive}) {
	const {store} = useContext(Context);
	return (
		<div className={active ? "__modal_logout active" : "__modal_contact"} onClick={() => setActive(false)}>
			<div className={active ? "__modal_content active" : "__modal_content"} onClick={e => e.stopPropagation()}>
				<div className="__logout">
					<p>Вы действително хотите выйти?</p>
					<div className="btn_group">
						<button onClick={() => setActive(false)}>Нет</button>
						<button onClick={() => store.logout()}>Выйти</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(ConfirmLogout);