import React from 'react';
import "./Main.scss";
import {motion} from "framer-motion";
import Header from "../../components/Header/Header";
import About from "../../components/About/About";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import Services from "../../components/Services/Services";
import PartnersBanner from "../../components/PartnersBanner/PartnersBanner";
import Faq from "../../components/FAQ/FAQ";
import OrderInfo from "../../components/OrderInfo/OrderInfo";

function Main(props) {

	return (
		<motion.div
			className="main"
			intial={{width: 0}}
			animate={{width: "100%"}}
			exit={{x: window.innerWidth, transition: {duration: 0.3}}}
		>
			<Header/>
			<About/>
			<OrderInfo/>
			{/*<ProductCarousel products={props.products}/>*/}
			<Services services={props.services}/>
			<PartnersBanner setSelectedLink={props.setSelectedLink}/>
			<Faq/>
		</motion.div>
	);
}

export default Main;