import React from 'react';
import "./Products.scss";
import {motion} from "framer-motion";
import more from "../../img/carouselMoreArrow.svg";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../http";

function Products({products}) {
	const navigate = useNavigate()

	return (
		<motion.div
			className="products"
			intial={{width: 0}}
			animate={{width: "100%"}}
			exit={{x: window.innerWidth, transition: {duration: 0.3}}}
		>
			<div className="container">
				<div className="container-top">
					{/*<div className="products-wrapper">*/}
					{/*	{*/}
					{/*		products.map((item, i) =>*/}
					{/*			<div className="product-item" key={i}>*/}
					{/*				<div className="item-img">*/}
					{/*					<img src={`${API_URL}/static/${item.logo}`} alt="img"/>*/}
					{/*					<h2>{item.name}</h2>*/}
					{/*				</div>*/}
					{/*				<p className="item-info">{item.info}</p>*/}
					{/*				<button onClick={ () => {*/}
					{/*					navigate(`/product-info/${item.url.toLowerCase()}`)*/}
					{/*					window.scrollTo(0,0);*/}
					{/*				}}>*/}
					{/*					Подробнее*/}
					{/*					<img src={more} alt="img"/>*/}
					{/*				</button>*/}
					{/*			</div>*/}
					{/*		)*/}
					{/*	}*/}
					{/*	<h3></h3>*/}
					{/*</div>*/}
				</div>
			</div>
		</motion.div>
	);
}

export default Products;