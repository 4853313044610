import React, {useContext, useState} from 'react';
import "./ServiceInfo.scss"
import {useNavigate, useParams} from "react-router-dom";
import {API_URL} from "../../http";
import {observer} from "mobx-react-lite";
import ConsultForm from "../ConsultForm/ConsultForm";
import OrderForm from "../OrderForm/OrderForm";
import {Context} from "../../index";
import Spinner from "../Spinner/Spinner";

function ServiceInfo({services}) {
	const {store} = useContext(Context);
	const [modalActive, setModalActive] = useState(false);
	const [orderModalActive, setOrderModalActive] = useState(false);
	const {url} = useParams();
	const service = services.find(item => item.url === url);
	const navigate = useNavigate();
	const orderService = () => {
		store.isAuth ?
			setOrderModalActive(true) :
			navigate("/signin")
	}

	if (!service) {
		return <Spinner/>;
	}

	return (
		<div className="__services_info">
			<div className="__header">
				<div className="container-top">
					<div className="__title">
						<div className="__hero">
							<h1>{service.name}</h1>
							<p>{service.description}</p>
							<h4>Длительность работ: {service.duration}</h4>
							<div className="__btn_group">
								<button onClick={orderService}>Заказать услугу</button>
								<button onClick={() => setModalActive(true)}>Получить консультацию</button>
							</div>
						</div>
						<div className="__logo">
							<img src={`${API_URL}/static/${service.image}.png`} alt="img"/>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="__steps">
					<h2>Процесс работ</h2>
					<div className="__process">
						{service.benefits.map((item, index) => (
							<div className="__column" key={index}>
								<div className="line">
									<div className="circle"></div>
								</div>
								<div className="title" >
									<h5>{item.title}</h5>
									<p>{item.benefit}</p>
								</div>
							</div>
						))}
					</div>
				</div>

			{
				service.in_person === 1 &&
				(
				<div className="__technologies">
					<h2>Технологический стэк</h2>
					<div className="__items">
						{
							service.technologies.map((item, i) => (
						<div className="card" key={i}>
							<div className="image">
								<img src={`${API_URL}/static/${item.img}.svg`} alt="img"/>
							</div>
							<p>{item.name}</p>
						</div>
						))}
					</div>
				</div>
				)}
			</div>
			<ConsultForm modalActive={modalActive} setModalActive={setModalActive}/>
			<OrderForm orderModalActive={orderModalActive} setOrderModalActive={setOrderModalActive} serviceName={service.name} serviceId={service.id}/>
		</div>
	)
}

export default observer(ServiceInfo);