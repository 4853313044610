import React, {useState} from 'react';
import "./Services.scss";
import serviceImg from "../../img/servicesImgMain.png";
import tick from "../../img/servicesTick.svg";
import {useNavigate} from "react-router-dom";

function Services({services}) {
	const [filter, setFilter] = useState('online');
	const navigate = useNavigate();

	const filteredServices = services.filter(service =>
		filter === 'online' ? service.in_person === 1 : service.in_person === 0

	);

	const linkToService = (item) => {
		navigate(`service-info/${item.url}`);
		window.scrollTo(0, 0);
	}


	return (
		<div className="services_main">
			<div className="container">
				<div className="services-content">
					<div className="services-text">
						<h2>Наши услуги</h2>
						<div className="__tumbler">
							<button className={filter === 'online' ? 'active' : ''}
							        onClick={() => setFilter('online')}>Онлайн
							</button>
							<button className={filter === 'in-person' ? 'active' : ''}
							        onClick={() => setFilter('in-person')}>Очно
							</button>
						</div>

						{filteredServices.map((item, index) =>
							<ul key={index}>
								<img src={tick} alt="img"/>
								<li onClick={() => linkToService(item)}>{item.name}</li>
							</ul>
						)}

					</div>
					<div className="services-image">
						<img src={serviceImg} alt="img"/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Services;