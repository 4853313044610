import React, {useContext} from 'react';
import "./ModalPay.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function ModalPay({modalOpen, setModalOpen, price, setError}) {
	const {store} = useContext(Context);
	const handlePurchase = () => {
		if(store.user.balance < price){
			console.log("No funds")
			setError(true);
			setModalOpen(false);

		}else{
			console.log("Top up") // tbank acquiring func
		}
	}
	return (
		<div className={modalOpen ? "__modal_pay active" : "__modal_pay"} onClick={() => setModalOpen(false)}>
			<div className={modalOpen ? "__modal_content active" : "__modal_content"} onClick={e => e.stopPropagation()}>
				<div className="__pay">
					<p>Подтвердить покупку</p>
					<div className="btn_group">
						<button onClick={handlePurchase}>Подтвердить</button>
						<button onClick={() => setModalOpen(false)}>Отмена</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(ModalPay);