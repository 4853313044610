import React from 'react';
import "./OrderInfoCard.scss";

function OrderInfoCard({ imageUrl, title, text }) {
	return (
		<div className="orderInfo__card">
			<img src={imageUrl} alt="Card Image" className="card__image" />
			<div className="card__content">
				<h3 className="card__title">{title}</h3>
				<p className="card__text">{text}</p>
			</div>
		</div>
	);
}

export default OrderInfoCard;