import React from 'react';
import "./ProductCarousel.scss";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import more from "../../img/carouselMoreArrow.svg";
import nextBtn from "../../img/carouselNext.svg";
import {useNavigate} from "react-router-dom";

function ProductCarousel({products}) {
	const navigate = useNavigate();
	const Previous = ({ next, previous }) => {
		return (
			<div className="carousel-button-group">
				<img className="prev" src={nextBtn} alt="img" onClick={() => previous()} />
				<img className="next" src={nextBtn} alt="img" onClick={() => next()} />
			</div>
		);
	};
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
			slidesToSlide: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 767 },
			items: 2,
			slidesToSlide: 1
		},
		mobile: {
			breakpoint: { max: 767, min: 0 },
			items: 1,
			slidesToSlide: 1,
		}
	};
	return (
		<div className="product-carousel">
			<div className="container">
				<Carousel
					swipeable={false}
					draggable={true}
					showDots={false}
					responsive={responsive}
					infinite={true}
					autoPlay={false}
					keyBoardControl={true}
					arrows={false}
					customButtonGroup={<Previous/>}
					customTransition="all 5"
					removeArrowOnDeviceType={["tablet", "mobile"]}
				>
					{
						products.map((item, index) =>
							<div className="carousel-info" key={index}>
								<div className="carousel-item">
									<div className="hero" style={{background: item.color, borderTopLeftRadius: 20, borderTopRightRadius: 20}}>
										<img src={item.logo} alt="img"/>
										<h1>{item.name}</h1>
									</div>
									<div className="info">
										<p>{item.info}</p>
									</div>
									<button onClick={ () => {
										navigate(`/product-info/${item.name.toLowerCase()}`)
										window.scrollTo(0,0);
									}}>
										Подробнее
										<img src={more} alt="img"/>
									</button>
								</div>
							</div>
						)
					}
				</Carousel>
			</div>
		</div>
	);
}

export default ProductCarousel;