import React, {useContext, useState} from 'react';
import "./ProfileAccount.scss";
import accountImg from "../../img/accountUserImg.svg";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import ConfirmLogout from "../../components/ConfirmLogout/ConfirmLogout";
import History from "../History/History";
import PayForm from "../PayForm/PayForm";

function ProfileAccount() {
	const [dropdownIsActive, setDropdownIsActive] = useState(false);
	const [active, setActive] = useState(false);
	const {store} = useContext(Context);

	return (
		<div className="account">
			<div className="__header">
				<div className="__title">
					<img src={accountImg} alt="img"/>
					<p>{store.user.email}</p>
				</div>
				<div className={`__info ${dropdownIsActive ? "active" : ""}`}>
					<div className="__item">
						<p>User ID</p>
						<span>{store.user.id}</span>
					</div>
					<div className="__item">
						<p>Баланс</p>
						<span>{store.user.balance}&#8381;</span>
					</div>
					<div className="__item display_none">
						<p>Выход</p>
						<svg onClick={() => setActive(true)} className="logout_img" width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
							<g id="SVGRepo_iconCarrier"> <path d="M21 12L13 12" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M18 15L20.913 12.087V12.087C20.961 12.039 20.961 11.961 20.913 11.913V11.913L18 9" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
							</g>
						</svg>
					</div>
				</div>
				<div className="__dropdown" onClick={() => setDropdownIsActive(!dropdownIsActive)}>
					<svg fill="iconNormal" size="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
					     className={`bn-svg ${dropdownIsActive ? "active" : ""}`}>
						<path fillRule="evenodd" clipRule="evenodd"
						      d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
						      fill="#000">
						</path>
					</svg>
				</div>
				{
					active && <ConfirmLogout active={active} setActive={setActive}/>
				}
			</div>
			<PayForm/>
			<div className="history_wrapper">
				<h2>История транзакций</h2>
				<History/>
			</div>

		</div>
	);
}

export default observer(ProfileAccount);