import React from 'react';
import "./Privacy.scss";

function Privacy() {
	return (
		<div className="privacy">
			<div className="container-top">
				<div className="container">
					<h1>Политика конфиденциальности и использования файлов cookie ООО "ДИТ"</h1>
					<ul className="__list">
						<li className="title">Общие положения</li>
						<ul className="__sublist">
							<li>1.1. Настоящая Политика конфиденциальности и использования файлов cookie (далее –
								"Политика") регулирует порядок обработки и защиты персональных данных пользователей
								(далее – "Пользователи") сайта dit-company.ru (далее – "Сайт"), предоставляемого
								Обществом с ограниченной ответственностью "ДИТ" (далее – "Компания").
							</li>
							<li>1.2. Использование Сайта означает безоговорочное согласие Пользователя с настоящей
								Политикой и условиями обработки его персональной информации, указанной в ней. В случае
								несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.
							</li>
						</ul>
						<li className="title">Сбор персональных данных</li>
						<ul className="__sublist">
							<li>2.1. Компания собирает минимальный объем персональных данных, необходимый для
								обеспечения использования Сайта и предоставления услуг. Сюда входят:
							</li>
							<ul className="__dot_list">
								<li>Адрес электронной почты</li>
								<li>Пароль</li>
								<li>Номер телефона</li>
								<li>Имя</li>
								<li>Прочие данные, являющиеся обязательными для заполнения на страницах сайта</li>
							</ul>
							<li>2.3. Персональные данные собираются при регистрации на Сайте, заполнении форм, а также
								при последующем его использовании.
							</li>
						</ul>
						<li className="title">Использование персональных данных</li>
						<ul className="__sublist">
							<li>3.1. Компания использует персональные данные для следующих целей:</li>
							<ul className="__dot_list">
								<li>Предоставление доступа к Сайту и его сервисам</li>
								<li>Идентификация Пользователя при входе на Сайт</li>
								<li>Обработка запросов и заявок Пользователей</li>
								<li>Улучшение качества работы Сайта и услуг Компании</li>
								<li>Обратная связь с Пользователями через формы</li>
								<li>Предоставление информации о сотрудничестве и партнёрских программах</li>
							</ul>
							<li>3.2. Компания не передает персональные данные третьим лицам, за исключением случаев, предусмотренных законодательством Российской Федерации.</li>
						</ul>
						<li className="title">Защита персональных данных</li>
						<ul className="__sublist">
							<li>4.1. Компания принимает все необходимые организационные и технические меры для защиты
								персональных данных Пользователей от неправомерного или случайного доступа, уничтожения,
								изменения, блокирования, копирования, распространения, а также от иных неправомерных
								действий третьих лиц.
							</li>
							<li>4.2. Доступ к персональным данным имеют только те сотрудники Компании, которым это необходимо для выполнения служебных обязанностей.</li>
						</ul>
						<li className="title">Права Пользователей</li>
						<ul className="__sublist">
							<li>5.1. Пользователи имеют право на получение информации, касающейся обработки их
								персональных данных, включая подтверждение факта обработки данных, правовые основания и
								цели обработки, сроки обработки и другие.
							</li>
							<li>5.2. Пользователи имеют право требовать от Компании уточнения их персональных данных, их
								блокирования или уничтожения в случае, если данные являются неполными, устаревшими,
								неточными, незаконно полученными или не являются необходимыми для заявленной цели
								обработки.
							</li>
							<li>5.3. Для реализации своих прав Пользователи могут направить соответствующий запрос на адрес электронной почты info@dit-company.ru</li>
						</ul>
						<li className="title">Использование файлов cookie</li>
						<ul className="__sublist">
							<li>6.1. Сайт использует файлы cookie для обеспечения нормальной работы Сайта, повышения
								удобства его использования и улучшения качества предоставляемых услуг.
							</li>
							<li>6.2. Файлы cookie представляют собой небольшие текстовые файлы, сохраняемые на
								устройстве Пользователя, которые используются для хранения информации о действиях
								Пользователя на Сайте.
							</li>
							<li>6.3. Компания использует следующие виды файлов cookie:</li>
							<ul className="__dot_list">
								<li>Обязательные файлы cookie: необходимы для функционирования Сайта и предоставления услуг.</li>
								<li>Функциональные файлы cookie: используются для запоминания предпочтений Пользователя и улучшения функциональности Сайта.</li>
								<li>Аналитические файлы cookie: собирают информацию о том, как Пользователи взаимодействуют с Сайтом, что позволяет улучшать его работу.</li>
							</ul>
							<li>6.4. Пользователи могут управлять использованием файлов cookie через настройки своего браузера. Отключение файлов cookie может привести к ограничению доступа к некоторым функциям Сайта.</li>
						</ul>
						<li className="title">Изменения в Политике</li>
						<ul className="__sublist">
							<li>7.1. Компания оставляет за собой право вносить изменения в настоящую Политику. Изменения вступают в силу с момента публикации новой версии Политики на Сайте.</li>
							<li>7.2. Продолжение использования Сайта после внесения изменений означает согласие Пользователя с новыми условиями Политики.</li>
						</ul>
						<li className="title">Контактная информация</li>
						<ul className="__sublist">
							<li>8.1. В случае возникновения вопросов или предложений по поводу настоящей Политики,
								Пользователи могут обратиться к Компании по указанной ниже контактной информации:
							</li>
							<ul className="__dot_list">
								<li>Адрес: 420029, Республика Татарстан, г. Казань, ул. Сибирский тракт, д. 32</li>
								<li>Электронная почта: info@dit-company.ru</li>
								<li>Телефон: +7 (967) 367 16 12</li>
							</ul>
						</ul>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Privacy;