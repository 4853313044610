import React, {useContext, useEffect, useState} from 'react';
import "./ProfileServices.scss";
import emptyImg from "../../img/transactionEmptyImg.svg";
import {useNavigate} from "react-router-dom";
import UserService from "../../services/UserService";
import Spinner from "../Spinner/Spinner";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import ModalPay from "../ModalPay/ModalPay";
import PaymentService from "../../services/PaymentService";

function ProfileServices() {
	const [service, setService] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedPrice, setSelectedPrice] = useState(0);
	const [noFundsError, setNoFundsError] = useState(false);
	const navigate = useNavigate();
	const {store} = useContext(Context);

	// const service = [
	// 	{
	// 		serviceId: 1,
	// 		name: "Веб-разработка",
	// 		duration: "От одной недели",
	// 		price: 500,
	// 		status: "created",
	// 		startTime: "11 ноября 2023, 12:00",
	// 		finishedTime: "11 ноября 2024, 12:00",
	// 	}
	// ]

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await UserService.getOrderedServices(store.user.id);
				setService(response.data);
				setLoading(false);

			} catch (e) {
				console.log(e);
				setLoading(false);
			}
		}

		fetchData().then(r => r);
	}, []);

	const handlePayment = (price) => {     // Сделать body.lock
		setSelectedPrice(price);
		setModalOpen(true);
	}

	const handleTopUpBalance = async (price) => {
		const amountToPay = (parseFloat(price) - store.user.balance).toString();
		try {
			// await store.createPayment(amountToPay, store.user.email, false);
			console.log("ProfileServices")
		} catch (error) {
			console.error('Ошибка при регистрации:', error);
		}
	}
	const handleLinkService = () => {
		navigate("/services");
	}

	return (
		<div className="service_profile">
			{
				loading ?
					<Spinner/> :
					service ?
						<div className="__service_profile_wrapper">
							{/*<h1>Заказанные услуги</h1>*/}
							{
								service.map((item, i) =>
									<div className={item.status === "finished" ? "__service_profile_item finished" : "__service_profile_item"} key={i}>
										<div className="__title">
											<h2>{item.name}</h2>
											<h6>&#8470;{item.service_id}</h6>
										</div>
										<p>{item.duration}</p>
										<div className="__steps">
											<div className="__process">
												<div className="__column">
													<div className={item.status === "finished" ? "line start finished" : "line start"}></div>
													<div
														className={item.status === "created" || item.status === "pay" || item.status === "confirmed" || item.status === "finished" ?
															"circle done" :
															"circle"}></div>
													<div className="__date">
														{item.start_time ? <h6>{item.start_time}</h6> : null}
													</div>
													<div className="text">
														<h5>Обработка</h5>
													</div>
												</div>
												<div className="__column">
													<div
														className={item.status === "pay" || item.status === "confirmed" || item.status === "finished" ?
															"circle done" :
															"circle"}></div>
													<div className="text">
														<h5>Оплата</h5>
													</div>
												</div>
												<div className="__column">
													<div className={item.status === "confirmed" || item.status === "finished" ?
														"circle done" :
														"circle"}></div>
													<div className="text">
														<h5>В работе</h5>
													</div>
												</div>
												<div className="__column">
													<div className={item.status === "finished" ? "line end finished" : "line end"}></div>
													<div className={item.status === "finished" ?
														"circle done" :
														"circle"}></div>
													<div className="__date">
														{item.finished_time ? <h6>{item.finished_time}</h6> : null}
													</div>
													<div className="text">
														<h5>Завершено</h5>
													</div>
												</div>
											</div>
										</div>
										{
											item.status === "pay" &&
											<div className="total-price">
												<p>К оплате: <span>{item.price}&#8381;</span></p>
											</div>
										}
										<div className="__btn">
											<button disabled={item.status === "created"}
											        onClick={item.status === "pay" ? () => handlePayment(item.price) : item.status === "finished" ? handleLinkService : null}>
												{item.status === "created" ? "Обработка заказа" : null}
												{item.status === "pay" ? "Оплатить" : null}
												{item.status === "confirmed" ? "Оплачено" : null}
												{item.status === "finished" ? "Новый заказ" : null}
											</button>
											{
												item.status === "pay" &&
												<button onClick={() => handleTopUpBalance(item.price)} className="balance_top_up">Пополнить</button>
											}
										</div>
										{noFundsError && <p className="error-message">Не хватает средств. Пополните баланс!</p>}

									</div>
								)
							}
						</div> :
						<div className="__empty">
							<div className="__image">
								<img src={emptyImg} alt="img"/>
							</div>
							<h2>На данный момент у вас нет заказанных продуктов и услуг</h2>
						</div>
			}
			{modalOpen && <ModalPay modalOpen={modalOpen} setModalOpen={setModalOpen} price={selectedPrice} setError={setNoFundsError}/>}
		</div>

	);
}

export default observer(ProfileServices);