import React, {useCallback, useState, useEffect, useContext} from 'react';
import {observer} from "mobx-react-lite";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./Header.scss";
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";

function Header() {
	const {store} = useContext(Context);
	const navigate = useNavigate();

	const handleStart = () => {
		store.isAuth ? navigate("/services"): navigate("/signup");
		window.scrollTo(0, 0)
	}
	const particlesInit = useCallback(async engine => {
		await loadFull(engine);
	}, []);

	const particlesLoaded = useCallback(async container => {
			// await console.log(container);
		},
		[]);

	const words = ['клиентов.', 'стартапов.', 'бизнеса.', 'компаний.'];
	const [currentWord, setCurrentWord] = useState(words[0]);
	const [index, setIndex] = useState(0);
	const [isDeleting, setIsDeleting] = useState(false);
	const [text, setText] = useState('');
	const [delta, setDelta] = useState(200 - Math.random() * 100);
	const period = 2000;

	useEffect(() => {
		let ticker = setInterval(() => {
			tick();
		}, delta);

		return () => { clearInterval(ticker); };
	}, [text]);

	const tick = () => {
		let i = index % words.length;
		let fullText = words[i];
		let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

		setText(updatedText);

		if (!isDeleting && updatedText === fullText) {
			setIsDeleting(true);
			setDelta(period);
		} else if (isDeleting && updatedText === '') {
			setIsDeleting(false);
			setIndex(index + 1);
			setDelta(500);
		} else {
			setDelta(200 - Math.random() * 100);
		}
	};

	return (
		<header className="header">
			<div className="header-title">
				<h1>Цифровые решения для <span className="typed-text">{text}</span></h1>
				<button onClick={handleStart}>Начать</button>
			</div>
			<div className="particles-container">
			<Particles
				canvasClassName="particles"
				id="tsparticles"
				init={particlesInit}
				loaded={particlesLoaded}
				options={{
					background: {
						color: {
							value: '#fff',
						},
					},
					fpsLimit: 100,
					interactivity: {
						events: {
							onClick: {
								enable: false,
							},
							onHover: {
								enable: false,
							},
							resize: true,
						},
					},
					particles: {
						color: {
							value: "#0637d0",
						},
						links: {
							color: "#0637d0",
							distance: 150,
							enable: true,
							opacity: .5,
							width: .5,
						},
						collisions: {
							enable: true,
						},
						move: {
							direction: "none",
							enable: true,
							outModes: {
								default: "bounce",
							},
							random: false,
							speed: 2,
							straight: true,
						},
						number: {
							density: {
								enable: true,
								area: 1000,
							},
							value: 170,
						},
						opacity: {
							value: 0.5,
						},
						shape: {
							type: "circle",
						},
						size: {
							value: { min: 1, max: 2 },
						},
					},
					detectRetina: true,
				}}
			/>
			</div>
		</header>
	);
}

export default observer(Header);