import React, {useContext, useState} from 'react';
import "./ChangePasswordForm.scss";
import eyeShow from "../../img/eyeShow.svg";
import eyeHide from "../../img/eyeHide.svg";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

function ChangePasswordForm({activationLink}) {
	const {store} = useContext(Context);
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("")
	const [isVisiblePassword, setIsVisiblePassword] = useState(false);
	const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(false);
	const [identicalError, setIdenticalError] = useState(false);

	const togglePasswordVisibility = () => {
		setIsVisiblePassword(!isVisiblePassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setIsVisibleConfirmPassword(!isVisibleConfirmPassword);
	};

	const passwordHandler = (e) =>{
		e.preventDefault();
		setPassword(e.target.value)
	}

	const passwordConfirmHandler = (e) =>{
		e.preventDefault();
		setConfirmPassword(e.target.value)
	}


	const handleChangePassword = async () => {
		if(password !== confirmPassword){
			setIdenticalError(true);
			return;
		}else{
			setIdenticalError(false)
		}
		try {
			await store.changePassword(password, activationLink, store.user.id);
			if (!Object.keys(store.errors).length) {
				navigate('/signin');
			}
		} catch (error) {
			console.error('Ошибка при входе:', error);
		}
	}

	return (
		<div className="change_password_wrapper">
			<h3>Изменение пароля</h3>
			<p>После изменения пароля произойдет выход из аккаунта на всех устройствах, сайтах и приложениях, где вошли с текущим паролем</p>
			<div className="password-container">
				<input
					type={isVisiblePassword ? 'text' : 'password'}
					value={password}
					onChange={(e) => passwordHandler(e)}
					placeholder="Новый пароль"
					className={store.errors.password || identicalError ? 'invalid-input' : ''}
					autoComplete="current-password"
				/>
				{isVisiblePassword ? (
					<img onClick={togglePasswordVisibility} src={eyeShow} alt="img" />
				) : (
					<img onClick={togglePasswordVisibility} src={eyeHide} alt="img" />
				)}
			</div>
			<div className="password-container">
				<input
					type={isVisibleConfirmPassword ? 'text' : 'password'}
					value={confirmPassword}
					onChange={(e) => passwordConfirmHandler(e)}
					placeholder="Повторите новый пароль"
					className={store.errors.password || identicalError ? 'invalid-input' : ''}
					autoComplete="current-password"
				/>
				{isVisibleConfirmPassword ? (
					<img onClick={toggleConfirmPasswordVisibility} src={eyeShow} alt="img" />
				) : (
					<img onClick={toggleConfirmPasswordVisibility} src={eyeHide} alt="img" />
				)}
			</div>

			{store.errors.password && <div className="error-message">{store.errors.password}</div>}
			{store.errors.general && <div className="error-message">{store.errors.general}</div>}
			{identicalError && <div className="error-message">Пароли не совпадают</div>}

			<button onClick={handleChangePassword}>Изменить</button>
		</div>
	);
}

export default observer(ChangePasswordForm);
