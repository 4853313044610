import React from 'react';
import "./Footer.scss";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../img/logoBlack.svg"
import vk from "../../img/footerVk.svg";
import linkedIn from "../../img/footerLnkdIn.svg";
import telegram from "../../img/footerTelegram.svg";

function Footer(props) {
	const navigate = useNavigate();
	function scrollTop (){
		window.scrollTo(0,0);
	}
	function refreshPage(){
		navigate("/");
		window.location.reload();
		window.scrollTo(0,0);
	}

	const goToContact = () => {
		navigate("/contact");
		window.scrollTo(0,0);
		props.setSelectedLink(4);
	}
	return (
		<div className="footer">
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-logo">
						<img src={logo} alt="img" className="logo" onClick={refreshPage}/>
						<p>OOO"ДИТ" &copy;{new Date().getFullYear()}</p>
						<p>Все права защищены</p>
						<div className="footer-links">
							<Link to = "/privacy" onClick={scrollTop}>Политика конфиденциальности</Link>
							<Link to = "/rules" onClick={scrollTop}>Правила пользования</Link>
						</div>
					</div>
					<div className="footer-network">
						<button onClick={goToContact}>Связаться</button>
						<div className="networks">
							<a href="#" target="_blank" rel="noopener"><img src={telegram} alt="img"/></a>
							<a href="#" target="_blank" rel="noopener"><img src={linkedIn} alt="img"/></a>
							<a href="#" target="_blank" rel="noopener"><img src={vk} alt="img"/></a>
						</div>
						<a className="network-mail" href="mailto:info@dit-company.ru">info@dit-company.ru</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;