import React, {useState} from 'react';
import "./SignUp.scss";
import {motion} from "framer-motion";
import loginImg from "../../img/signPage.png";
import LoginForm from "../../components/LoginForm/LoginForm";
import ConfirmEmail from "../../components/ConfirmEmail/ConfirmEmail";

function Registration() {
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);
	const [confirmEmail, setConfirmEmail] = useState(false);
	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};
	return (
		<motion.div className="login" intial={{width: 0}} animate={{width: "100%"}}
		            exit={{x: window.innerWidth, transition: {duration: 0.3}}}>
			<div className="__wrapper">
				<div className="__image">
					<img src={loginImg} alt="img"/>
				</div>
				{
					confirmEmail ? <ConfirmEmail/> :
						<div className="__form">
							<LoginForm
								type="reg"
								isPrivacyAgreed={isPrivacyPolicyAgreed}
								setPrivacyPolicyValid={setIsPrivacyPolicyValid}
								setConfirmEmail={setConfirmEmail}
							/>
							<div className="form-agree">
								<div className="agree-text">
									<input
										type="checkbox"
										id="privacyPolicyAgree"
										checked={isPrivacyPolicyAgreed}
										onChange={handlePrivacyPolicyChange}
									/>
									<label htmlFor="privacyPolicyAgree">
										Вы соглашаетесь с
										<a href="/rules">Правилами пользования</a>
										<span>*</span>
									</label>
								</div>
								{!isPrivacyPolicyValid && (
									<p className="error-message">Подтвердите, что согласны с правилами пользования</p>
								)}
							</div>
						</div>
				}
			</div>
		</motion.div>
	);
}

export default Registration;