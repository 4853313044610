import React, {useState} from 'react';
import "./FAQ.scss";
import arrow from "../../img/faqArrow.svg";


function Faq() {
	const [selected, setSelected] = useState(null);

	const faqData = [
		{
			"question": "Какие у вас цены?",
			"answer": "Цены на наши услуги зависят от конкретных требований проекта. Мы предлагаем индивидуальный подход к каждому клиенту и стремимся предложить наиболее выгодные условия сотрудничества. Для получения подробной информации о ценах, пожалуйста, свяжитесь с нашей командой."
		},
		{
			"question": "Как долго занимает выполнение проекта?",
			"answer": "Время выполнения проекта зависит от его сложности и объема. Мы стараемся выполнить работы в кратчайшие сроки, учитывая все технические и организационные аспекты. Для каждого проекта мы составляем индивидуальный график выполнения работ."
		},
		{
			"question": "Вы работаете с юридическими лицами?",
			"answer": "Да, мы работаем с юридическими лицами. Если ваша компания заинтересована в наших услугах, пожалуйста, свяжитесь с нами для обсуждения деталей проекта. Мы подготовим индивидуальное предложение, учитывающее потребности вашего бизнеса."
		},
		{
			"question": "Как мне связаться с вашей командой?",
			"answer": "Вы можете связаться с нами через нашу контактную форму на сайте, отправив нам электронное письмо на указанный адрес, или позвонив по указанному номеру телефона. Наша команда оперативно ответит на все ваши вопросы и предоставит необходимую помощь."
		},
		{
			"question": "Предоставляете ли вы гарантии на свои услуги?",
			"answer": "Мы гарантируем качество предоставляемых услуг и стремимся обеспечить полное удовлетворение клиентов. В случае возникновения проблем мы предоставим необходимую поддержку и помощь для их решения."
		},
	]

	const handleToggle = (index) => {
		if (selected === index) {
			return setSelected(null);
		} else {
			setSelected(index);
		}
	};
	return (
		<div className="faq">
			<div className="container">
				<h1>Часто задаваемые вопросы</h1>
				<div className="accordion">
					{faqData.map((item, i) => (
						<div className="item" key={i} onClick={() => handleToggle(i)}>
							<div className="title" >
								<h4>{item.question}</h4>
								<img className={selected === i ? "spin" : ""} src={arrow} alt="img"/>
							</div>
							<div className = {selected === i ? "content active" : "content"}>
								<p>{item.answer}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Faq;