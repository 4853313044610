import $api from "../http";
import {AxiosResponse} from 'axios';
import {Transaction} from "../models/Transaction";

export default class UserService {
    static fetchTransactions(id: string): Promise<AxiosResponse<Transaction>> {
        return $api.post<Transaction>('/transactions', {id})
    }

    static createOrder(data: FormData): Promise<AxiosResponse> {
        return $api.post('/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static getOrderedServices(id: string): Promise<AxiosResponse>{
        return $api.post("/getOrderedServices", {id})
    }
}