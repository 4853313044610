import React, {useEffect, useState} from 'react';
import ModalContact from "../ModalContact/ModalContact";
import axios from "axios";
import {API_URL} from "../../http";

function ConsultForm({modalActive, setModalActive}) {
	useEffect(() => {
		if (modalActive) {
			document.body.classList.add('lock');
		} else {
			document.body.classList.remove('lock');
		}
	}, [modalActive]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [number, setNumber] = useState("");
	const [telegram, setTelegram] = useState("");
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);

	const [isValidName, setIsValidName] = useState(true);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isValidNumber, setIsValidNumber] = useState(true);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);

	const handleNameChange = (event) => {
		setName(event.target.value);
		setIsValidName(true);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setIsValidEmail(true);
	};
	const handleNumberChange = (event) => {
		let inputValue = event.target.value;
		inputValue = inputValue.replace(/[^0-9+()\-\s]/g, '');
		if (!inputValue.startsWith("+")) {
			inputValue = "+" + inputValue;
		}
		setNumber(inputValue);
		setIsValidNumber(true);
	};
	const handleTelegramChange = (event) => {
		setTelegram(event.target.value);
	}

	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const handleSubmit = async () => {
		const isValidName = name !== "";
		setIsValidName(isValidName);

		const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const isValidEmail = emailPattern.test(email);
		setIsValidEmail(isValidEmail);

		const isPhoneNumValid = number !== "" && number.length >= 8;
		setIsValidNumber(isPhoneNumValid);

		setIsPrivacyPolicyValid(isPrivacyPolicyAgreed);

		if(
			isValidName &&
			isValidEmail &&
			isValidNumber &&
			isPrivacyPolicyValid
		){
			const data = {
				name: name,
				email: email,
				number: number,
				telegram: telegram,
			};

			axios.post(`${API_URL}/sendConsult`, data)
				.then(response => {
					console.log("Successful sent");
				})
				.catch(error => {
					console.error('Oops... ' + error);
				});
			setModalActive(false);
			setName("");
			setEmail("");
			setNumber("");
			setTelegram("");
			setIsPrivacyPolicyAgreed(false);
		}
	}
	return (
		<ModalContact active={modalActive} setActive={setModalActive}>
			<div className="form">
				<h2>Свяжитесь с нами</h2>
				<div className="input-group">
					<div className="form-input">
						<label>Имя<span>*</span></label>
						<input type="text"
						       value={name}
						       onChange={handleNameChange}
						       className={!isValidName ? "invalid" : ""}
						/>
						{!isValidName && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="form-input">
						<label>Почта<span>*</span></label>
						<input type="text"
						       value={email}
						       onChange={handleEmailChange}
						       className={!isValidEmail ? "invalid" : ""}
						/>
						{!isValidEmail && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="form-input">
						<label>Номер телефона<span>*</span></label>
						<input type="text"
						       value={number}
						       onChange={handleNumberChange}
						       className={!isValidNumber ? "invalid" : ""}
						/>
						{!isValidNumber && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="form-input">
						<label>Телеграм</label>
						<input type="text"
						       value={telegram}
						       onChange={handleTelegramChange}
						/>
					</div>
				</div>
				<div className="btn">
					<button onClick={handleSubmit}>Отправить</button>
					<div className="form-agree">
						<div className="agree-text">
							<input
								type="checkbox"
								id="privacyPolicyAgree"
								checked={isPrivacyPolicyAgreed}
								onChange={handlePrivacyPolicyChange}
							/>
							<label htmlFor="privacyPolicyAgree">
								Вы соглашаетесь с
								<a href="/privacy"> политикой конфиденцальности</a>
								<span>*</span>
							</label>
						</div>
						{!isPrivacyPolicyValid && (
							<p className="error-message">Примитие политику конфиденцальности</p>
						)}
					</div>
				</div>
			</div>
		</ModalContact>
	);
}

export default ConsultForm;
