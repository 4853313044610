import React, {useState, useContext, useEffect} from "react";
import './App.css';
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import Main from "./pages/Main/Main";
import Products from "./pages/Products/Products";
import Partners from "./pages/Partners/Partners";
import Solutions from "./pages/Solutions/Solutions";
import Contacts from "./pages/Contacts/Contacts";
import ProductInfo from "./components/ProductInfo/ProductInfo";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Profile from "./pages/Profile/Profile";
import {AnimatePresence} from "framer-motion";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ServiceInfo from "./components/ServiceInfo/ServiceInfo";
import DataService from "./services/DataService";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import Privacy from "./pages/Privacy/Privacy";
import Rules from "./pages/Rules/Rules";



function App() {
    const {store} = useContext(Context);
    const [cookieAccepted, setCookieAccepted] = useState(localStorage.getItem("cookieAccepted") === "true");
    const [selectedLink, setSelectedLink] = useState(0);
    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchServices = async () => {
            const servicesData = await DataService.getServices();
            setServices(servicesData);
        };

        const fetchProducts = async () => {
            const productsData = await DataService.getProducts();
            setProducts(productsData);
        };

        fetchProducts();
        fetchServices();

        if(localStorage.getItem("token")){
            store.checkAuth();
        }
    }, []);

    const handleAcceptCookie = () => {
        setCookieAccepted(true);
        localStorage.setItem("cookieAccepted", "true");
    };


    if(store.isLoading){
        return <div></div>
    }




    const renderCondition = !location.pathname.includes("/signin") &&
                          !location.pathname.includes("/signup")
    return (
        <div className="App">
            <div className={`${cookieAccepted ? '' : 'blur'}`}>
                <Navigation selectedLink={selectedLink} setSelectedLink={setSelectedLink}/>
                <AnimatePresence>
                    <Routes location={location} key={location.pathname}>
                        <Route path="/" element={<Main setSelectedLink={setSelectedLink} products={products} services={services}/>}/>
                        <Route path="/products" element={<Products products={products}/>}/>
                        <Route path="/services" element={<Solutions services={services}/>}/>
                        <Route path="/partners" element={<Partners/>}/>
                        <Route path="/contact" element={<Contacts/>}/>
                        <Route path="/product-info/:url" element={<ProductInfo products={products}/>}/>
                        <Route path="/service-info/:url" element={<ServiceInfo services={services}/>}/>
                        <Route path="/signin" element={<SignIn/>}/>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/profile" element={<PrivateRoute/>}>
                            <Route path="" element={<Profile/>}/>
                        </Route>
                        <Route path="/reset" element={<ResetPassword/>}/>
                        <Route path="/privacy" element={<Privacy/>}/>
                        <Route path="/rules" element={<Rules/>}/>
                    </Routes>
                </AnimatePresence>
                {renderCondition && <Footer setSelectedLink={setSelectedLink}/>}
            </div>
            {!cookieAccepted && <CookieConsent onAccept={handleAcceptCookie} />}
        </div>
    );
}

export default observer(App);
