import React from 'react';
import "./ProfileSupport.scss";

function ProfileSupport(props) {
	return (
		<div></div>
	);
}

export default ProfileSupport;
